import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const RecentPosts = () => {
	const data = useStaticQuery(graphql`
		query {
			dnhCms {
				blogPosts(where: {status: PUBLISHED, featuredPost: true}, orderBy: createdAt_DESC) {
					id
					url
					title
				}
			}
		}
	`)
	return (
		<div className="widget recent-posts">
			<h3 className="widget-title">Featured Post</h3>
			<ul>
				{data.dnhCms.blogPosts.map(post => (
					<li key={post.id}>
						<Link
							to={`/blog/${post.url.replace(/\s+/g, '-')}/`}>
							<h2>{post.title}</h2>
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}

export default RecentPosts
