import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import SEO from "../components/seo"
import Layout from "../components/layout"
import FeaturedPosts from "../components/featuredPosts"
import GraphImg from "graphcms-image";

const Blog = (props) => {

	const {data, pageContext} = props;
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.pages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.pages[0].bannerHeading}
					bannerUrl={data.dnhCms.pages[0].pageBanner.url}>
				<SEO title={data.dnhCms.pages[0].pageTitle}
					description={data.dnhCms.pages[0].pageDescription}/>
				<div className="container blog-page-wrap">
					<div className="blog-list">
						{data.dnhCms.blogPosts.map(post => (
							<div key={post.id} className="blog-list-item">
								<div className="blog-list-img">
									<Link
										to={`/blog/${post.url.replace(/\s+/g, '-')}/`}>
										<GraphImg image={post.image}
											fit="scale"
											backgroundColor={true}
											withWebp={true}
											alt={post.image.fileName} />
										<i className="far fa-newspaper"></i>
									</Link>
								</div>
								<div className="blog-list-box">
									<div className="blog-list-box__heading">
										<Link
											to={`/blog/${post.url.replace(/\s+/g, '-')}/`}>
											<h2>{post.title}</h2>
										</Link>
										<p>By <span>{post.author.split('_').join(' ')}</span> {post.date.substring(0, post.date.indexOf('T'))}</p>
									</div>
									<div className="blog-list-box__body">
										<p>{post.shortDescription.slice(0, 250)}..</p>
									</div>
									<div className="blog-list-box__footer">
										<Link
											to={`/blog/${post.url.replace(/\s+/g, '-')}/`}>
											Read more<i className="fas fa-arrow-right" aria-hidden="true"></i>
										</Link>
									</div>
								</div>
							</div>
						))}
						<div className="pagination-wrap">
							{pageContext.currentPage !== 1 &&
								<Link to={pageContext.currentPage - 1 === 1 ? '/blog' : `/blog/${pageContext.currentPage - 1}`}><i className="fas fa-arrow-left"></i> Previous</Link>
							}
							{pageContext.currentPage !== pageContext.numPages &&
								<Link to={`/blog/${pageContext.currentPage + 1}`}>Next <i className="fas fa-arrow-right"></i></Link>
							}
						</div>
					</div>
					<aside className="blog-list-aside">
						<FeaturedPosts/>
					</aside>
				</div>
			</Layout>
		</>
	)
}

Blog.propTypes = {
	pageContext: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	location: PropTypes.object
}

export default Blog

export const blogQuery = graphql`
	query($skip: Int!, $limit: Int!){
		dnhCms {
			pages(where: {pageTitle: "Blog"}) {
				id
				bannerHeading
				pageTitle
				pageDescription
				heading
				copy
				pageBanner {
					url
				}
			}
			blogPosts(where: {status: PUBLISHED}, orderBy: createdAt_DESC, skip: $skip, first: $limit){
				id
				url
				title
				shortDescription
				author
				date
				image {
					handle
					width
					height
					fileName
				}
				body
			}
		}
	}
`;
